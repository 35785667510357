import React, { useState } from 'react';
import B2B from '../B2B/B2B';
import Banner from '../Banner/Banner';
import Footer from '../Footer/Footer';
import Intouch from '../intouch/Intouch';
import Products from '../products/Products';
import StartUp from '../startup/StartUp';

const Home = () => {
    const [user, setUser] = useState(false)

    return (
        <div>
            <Banner />
            <StartUp />
            <B2B />
            <Products />
            <div className=' mt-2'>
                <h1 className='lg:text-5xl text-2xl md:text-4xl  font-bold text-center'>To know More about it</h1>
                <div className=''>
                    <button className='flex mx-auto text-white booking-modal mt-3'> <label className=' py-2 text-white text-xl btns btn btn-primary bg-orange-400' htmlFor="booking-modal"
                        onClick={() => setUser(true)}
                    >Get In Touch</label></button>
                </div>
            </div>
            {user && <Intouch />}
            <Footer />
        </div>
    );
};

export default Home;