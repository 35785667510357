import React, { useState } from 'react';
import Intouch from '../intouch/Intouch';

const Banner = () => {
    const [user, setUser] = useState(false)

    return (
        <div className='bg-banner-pattern bg-no-repeat py-7 lg:py-14 bg-cover '>
            <div className='text-center lg:pb-40 lg:pt-14 pt-5'>
                <h1 className='lg:text-9xl text-5xl font-bold'><span className='text-white'>Talent</span><span className='text-yellow-400'>Box</span></h1>
                <h2 className='lg:text-7xl text-2xl text-white mt-5 lg:mt-7'>SecretSoup Coming Soon.</h2>
                <h4 className='text-white mt-2 lg:mt-8 lg:text-2xl text-xl'>Get a Glimpse of Who We are , How we do and<br />What you get</h4>
                <div className='mt-5 sm:mb-14 lg:mt-7'>
                    <button className='flex mx-auto text-white booking-modal mt-3'> <label className=' py-2 text-white text-xl btns btn btn-primary bg-cyan-400' htmlFor="booking-modal"
                        onClick={() => setUser(true)}
                    >Get In Touch</label></button>
                </div>
            </div>
            {user && <Intouch />}
        </div>
    );
};

export default Banner;