import React from 'react';
import productsimg from '../../images/image3.png'

const Products = () => {
    return (
        <div className=" hero ">
            <div className="hero-content flex-col lg:gap-20 md:flex-row lg:flex-row h-full w-full">
                <img src={productsimg} alt="" className="max-w-sm " data-aos="fade-up-right" />
                <div data-aos="fade-up-left">
                    <h1 className="lg:text-5xl text-2xl md:text-3xl font-bold">Our products have <br /> the backbone of AI, <br /> ML, Data Science, <br /> and Blockchain.</h1>


                </div>

            </div>
        </div>
    );
};

export default Products;