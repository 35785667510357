import React from 'react';
import { FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi'
// import microsoft from '../../images/microsoft.jpeg';
import './Footer.css'

const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <footer className="  bg-[#3b82f6] mt-3 text-neutral-content">
            <div className='footer items-center justify-between p-4'>
                <div>
                    <h1 className='text-white text-4xl font-bold'><span>Talent</span><span className='text-yellow-400'>Box</span> <span>Labs</span></h1>

                </div>
                <div className='links'>
                    <h1 className='text-2xl text-white font-bold flex icons-head justify-center w-full lg:mb-2'><span className="tooltip mr-7" data-tip="Facebook"><a href='https://www.facebook.com/TalentBox-Labs-100128982828568' className=' footer_icon'><FaFacebookF /></a></span><span className="tooltip mr-7" data-tip="Linkedin"><a href='https://www.linkedin.com/company/talentbox-labs' className=' footer_icon'><FaLinkedinIn /></a></span><span className="tooltip mr-7" data-tip="Twitter"><a href='https://twitter.com/talentbox_labs' className=' footer_icon'><FaTwitter /></a></span></h1>
                    {/* <footer className='microsoft'>
                        <img src={microsoft} alt="" />
                    </footer> */}
                </div>
                <div>
                    <div className="form-control w-80">
                        <label className="label">
                            <span className="label-text text-white">Stay tuned to get latest updates on <br />our products</span>
                        </label>
                        <div className="relative ll">
                            <form >
                                <input type="email" placeholder="username@site.com" className="input input-bordered w-full pr-16 text-gray-700" required />
                                <button className="btn bg-yellow-500 border-none absolute top-0 right-0 rounded-l-none text-white font-bold text-xl"><HiOutlineArrowNarrowRight /></button>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
            <footer className='footer px-5'>
                <h1 className='text-base font-bold'>&copy; {year}, All rights reserved</h1>
            </footer>

        </footer>

    );
};

export default Footer;