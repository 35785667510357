import React from 'react';
import B2Bimg from '../../images/image2.png';

const B2B = () => {
    return (
        <div className=" hero ">
            <div className="hero-content flex-col lg:gap-20 md:flex-row-reverse lg:flex-row-reverse h-full w-full">
                <img src={B2Bimg} alt="" className="max-w-sm " data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom" data-aos-duration="400" />
                <div data-aos="fade-down"
                    data-aos-anchor-placement="top-bottom" data-aos-duration="400">
                    <h1 className="lg:text-5xl text-2xl md:text-3xl font-bold">We are building a B2B <br />SaaS platform.</h1>


                </div>

            </div>
        </div>
    );
};

export default B2B;