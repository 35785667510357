import React from 'react';
import startupimg from '../../images/Image1.png'

const StartUp = () => {
    return (
        <div className=" hero lg:mt-[-60px] ">
            <div className="hero-content flex-col lg:gap-20 md:flex-row lg:flex-row h-full w-full">
                <img src={startupimg} alt="" className="max-w-sm" data-aos="fade-right"
                    data-aos-offset="300"
                    data-aos-duration="600"
                    data-aos-easing="ease-in-sine" />
                <div data-aos="fade-left"
                    data-aos-offset="300"
                    data-aos-duration="600"
                    data-aos-easing="ease-in-sine">
                    <h1 className="lg:text-5xl text-2xl md:text-3xl font-bold">We are an HR Deep<br />Tech start-up.</h1>


                </div>

            </div>
        </div>
    );
};

export default StartUp;