import React from 'react';
import emailjs from '@emailjs/browser'
import { useNavigate } from 'react-router-dom';

const Intouch = () => {
    const navigate = useNavigate();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_s6m4bwl', 'template_o7ntrdj', e.target, 'q6SL2OTvdNDYgtIpb').then(res => {
            console.log(res)
            if (res?.EmailJSResponseStatus?.status === 200) {
                navigate('/home')
            }
        }).catch(err => console.log(err))
    }
    return (
        <div>
            <input type="checkbox" id="booking-modal" className="modal-toggle" />
            <div className="modal modal-bottom sm:modal-middle">
                <div className="modal-box">
                    <label htmlFor="booking-modal" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <h3 className="font-bold text-2xl text-center text-secondary">Get In Touch</h3>
                    <form onSubmit={sendEmail} className='grid grid-cols-1 justify-items-center mt-3 gap-4'>

                        <input type="text" name='name' placeholder='Name' className="input input-bordered input-primary w-full max-w-xs" required />
                        <input type="number" name='phone' placeholder='Phone Number(+91***)' className="input input-bordered input-primary w-full max-w-xs" required />

                        <input type="email" name='email' placeholder='Email Address' className="input input-bordered input-primary w-full max-w-xs" required />

                        <input type="text" name='company' placeholder="Company Name" className="input input-bordered input-primary w-full max-w-xs" required />
                        <input type="text" name='designation' placeholder="Job Title" className="input input-bordered input-primary w-full max-w-xs" required />
                        <input type="submit" value="Send" className=" btn btn-secondary w-full max-w-xs text-white" />
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Intouch;